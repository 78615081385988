<template>
	<div class="nav-list">
		<el-tabs v-model="TabsValue" @tab-click="tabclick" type="card" class="list" @tab-remove="removeTab">
			<el-tab-pane :closable="false" label="首页" name="/">
			</el-tab-pane>
			<el-tab-pane closable v-for="item in list" :key="item.path" :label="item.name" :name="item.path">
			</el-tab-pane>
		</el-tabs>
	</div>
</template>·

<script>
	export default {
		data() {
			return {
				TabsValue: ''
			}
		},
		computed: {
			list() {
				return this.$store.state.navlist
			}
		},
		// 监听路由变化
		watch: {
			$route(to, from) {
				this.TabsValue = to.path
			}
		},
		// 默认取当前路由
		mounted() {
			this.TabsValue = this.$route.path
		},
		methods: {
			// 点击路由
			tabclick(e) {
				this.$router.push(e.props.name)
			},
			// 关闭路由
			removeTab(targetName) {
				let ishome = false
				const tabs = this.$store.state.navlist
				let activeName = this.TabsValue
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.path === targetName) {
							const nextTab = tabs[index + 1] || tabs[index - 1]
							if (nextTab) {
								activeName = nextTab.path
							} else {
								ishome = true
								// 删除完了返回首页
								this.$router.push('/')
							}
						}
					})
				}
				this.TabsValue = activeName
				if (!ishome) {
					this.$router.push(this.TabsValue)
				}
				let newlist = tabs.filter((tab) => tab.path !== targetName)
				// 提交路由
				this.$store.dispatch('GETNAVLIST', newlist)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		padding: 10px;
		width: 100%;
		overflow-y: auto;

		::v-deep .el-tabs__item {
			display: inline-flex;
			align-items: center;
			border-radius: 3px;
			height: 30px;
			line-height: 30px;
			padding-left: 10px !important;
			padding-right: 10px !important;
			background-color: #fff;
			font-size: 12px;
			margin-right: 10px;
			color: #909399;
			cursor: pointer;

			i:hover {
				background-color: #00ad79;
				color: #FFFFFF;
			}

			i {
				transition: all .3s;
				height: 18px;
				text-align: center;
				line-height: 18px;
				font-size: 14px;
				margin-left: 5px;
				width: 0;
				overflow: hidden;
			}
		}
	}
</style>
