<template>
	<div class="head">
		<i class="icon iconfont icon-daohang" @click="isCollapse"></i>
		<view class="route-nav">
			<span @click="$router.push('/')" class="home">首页</span>
			<span class="line">/</span>
			<span>{{crumbname}}</span>
			<span v-if="sjname">{{sjname}}</span>
			<span class="line" v-if="sjname">/</span>
			<span v-if="qyname">{{qyname}}</span>
		</view>
		<!-- 用户 -->
		<div class="user">
			
			<div v-if="$store.state.villageId==0" class="msg" @click="$router.push({path:'/feedback',query:{state:0}})">
				<el-badge :value="Number($store.state.setTotal.resid_environ)"
					:hidden="Number($store.state.setTotal.resid_environ)>0?false:true" class="item">
					<p>人居环境反馈</p>
				</el-badge>
			</div>
			
			<div v-if="$store.state.villageId>0" class="msg" @click="$router.push({path:'/clique',query:{state:0}})">
				<el-badge :value="Number($store.state.setTotal.party_apply)"
					:hidden="Number($store.state.setTotal.party_apply)>0?false:true" class="item">
					<p>党员申请</p>
				</el-badge>
			</div>
			<div v-if="$store.state.villageId>0" class="msg" @click="$router.push({path:'/order',query:{state:2}})">
				<el-badge :value="Number($store.state.setTotal.shipped)"
					:hidden="Number($store.state.setTotal.shipped)>0?false:true" class="item">
					<p>待发货</p>
				</el-badge>
			</div>
			<div v-if="$store.state.villageId>0" class="msg" @click="$router.push({path:'/refund',query:{state:1}})">
				<el-badge :value="Number($store.state.setTotal.buytrueing)"
					:hidden="Number($store.state.setTotal.buytrueing)>0?false:true" class="item">
					<p>商品退款</p>
				</el-badge>
			</div>
			<div v-if="$store.state.villageId>0" class="msg" @click="$router.push({path:'/integralOrder',query:{state:1}})">
				<el-badge :value="Number($store.state.setTotal.isc_total)"
					:hidden="Number($store.state.setTotal.isc_total)>0?false:true" class="item">
					<p>兑换商品</p>
				</el-badge>
			</div>
			<div v-if="$store.state.villageId>0" class="msg" @click="$router.push({path:'/partyOrder',query:{state:2}})">
				<el-badge :value="Number($store.state.setTotal.party_fee_refund)"
					:hidden="Number($store.state.setTotal.party_fee_refund)>0?false:true" class="item">
					<p>党费退款</p>
				</el-badge>
			</div>
			<div class="username">
				<el-dropdown trigger="click">
					{{setdata.username}}
					<span>
						<img v-if="setdata.headimg" :src="setdata.headimg" />
						<img v-else src="../../assets/img/tx.png" />
					</span>
					<i class="icon iconfont icon-xiala"></i>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item @click="$router.push('/info')">个人资料</el-dropdown-item>
							<el-dropdown-item @click="signout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";

	export default {
		data() {
			return {
				crumbname: '数据统计',
				setdata: {},
				qyname: '',
				sjname: '',
			}
		},
		computed: {
			crumbname() {
				return this.$route.name
			},
			qyname() {
				return this.$route.query.name || this.$route.query.ername
			},
			sjname() {
				return this.$route.query.typename
			}
		},
		mounted() {
			this.getinfo()
			var that = this
			this.$EventBus.on('RefreshUser', function() {
				that.getinfo()
			})
			this.getstatistics()
			this.settime()
		},
		methods: {
			// 获取统计
			getstatistics() {
				this.$http.post(api.statistics)
					.then((res) => {
						// 提交
						this.$store.dispatch('SEtTOTAL', res.data)
					})
			},
			// 轮询
			settime() {
				let that = this
				this.timer = window.setInterval(() => {
					if (sessionStorage.getItem('token')) {
						that.getstatistics()
					}
				}, 10000)
			},
			// 菜单折叠
			isCollapse() {
				this.$EventBus.emit("IsCollapse");
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: sessionStorage.getItem('userid')
					})
					.then((res) => {
						this.setdata = res.data
					})
			},
			// 退出登录
			signout() {
				ElMessageBox.confirm(
						'是否确定退出登录?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						sessionStorage.removeItem('token')
						let getroute = []
						this.$store.dispatch('GETNAVLIST', getroute)
						this.$router.push('/login')
						ElMessage({
							type: 'success',
							message: '退出成功',
						})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>


<style lang="scss" scoped>
	::v-deep.el-dropdown-menu__item {
		font-size: 13px;
	}

	.head {
		display: flex;
		align-items: center;
		padding: 0 10px;
		width: 100%;
		font-size: 13px;
		height: 50px;
		background-color: #FFFFFF;

		.user {
			display: flex;
			align-items: center;

			.username {
				margin-left: 30px;
				cursor: pointer;


				img {
					width: 32px;
					height: 32px;
					margin: 0 10px;
					border-radius: 50%;
				}

				.icon-xiala {
					font-size: 14px;
					color: #999999;
				}
			}

			.msg {
				cursor: pointer;
				padding-right: 55px;

				i {
					font-size: 22px;
				}
			}
		}

		.route-nav {
			flex: 1;
			margin-left: 30px;

			.home {
				font-weight: bold;
				cursor: pointer;
			}

			.home:hover {
				color: #00ad79;
			}

			.line {
				margin: 0 12px;
				font-weight: bold;
				display: inline-block;
			}
		}

		.icon-daohang {
			cursor: pointer;
			font-size: 22px;
			font-weight: bold;
		}
	}
</style>
