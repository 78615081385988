<template>
	<div class="page-layout">
		<!-- 菜单栏 -->
		<div class="page-menu">
			<Menu></Menu>
		</div>
		<!-- 内容区 -->
		<right></right>
	</div>
</template>

<script>
	import Menu from './menu.vue'
	import right from './right.vue'
	export default {
		components: {
			Menu,
			right
		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-layout {
		width: 100%;
		display: flex;

		.page-menu {
			overflow: hidden;
			height: 100vh;
			background: #2f3447;
		}
	}
</style>
